import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './navbar.css'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Registration from './Registration/Registration'
import configureStore from './configureStore'
import RegisterSuccess from './Registration/RegisterSuccess'

const store = configureStore()
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={App} />
        <Route path='/registration/:goal' component={Registration} />
        <Route path='/registrationSuccess' component={RegisterSuccess} />
      </Switch>
    </BrowserRouter>
  </Provider>,
)

reportWebVitals()
