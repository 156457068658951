import React, { useCallback, useEffect, useState } from 'react'
import '../navbar.css'
import TextField from '../CustomTextField'
import Logo from '../img/img.png'
import backArrow from '../img/Screenshot 2022-11-07 at 19.25.22.png'
import InputLabel from '@mui/material/InputLabel'
import { useDispatch, useSelector } from 'react-redux'
import { create } from './action'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  InputBase,
  styled,
  Typography,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { createTheme, ThemeProvider } from '@mui/material/styles'

function Registration() {
  const theme = createTheme({
    palette: {
      bright: {
        main: '#fff',
      },
      primary: {
        main: '#fff',
      },
    },
  })

  const informationData = useSelector((state) => state.information)

  const history = useHistory()

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      // marginTop: 3,
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      color: '#FFF',
      // backgroundColor: theme.palette.background.paper,
      border: '1px solid #bac2be',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: 'white',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))
  const params = useParams()
  const dispatch = useDispatch()
  const [error, setError] = useState([])
  const [stateObj, setStateObj] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    func: '',
    contact: 'Ik wil gebeld worden',
    goal: params?.goal,
  })
  const [hasChanges, setHasChanges] = useState(0)

  const information = useCallback(() => {
    let newErr = error || []

    if (stateObj?.firstName?.length < 2) {
      newErr.push('Voornaam')
    } else {
      newErr.filter((it) => it !== 'Voornaam')
    }

    if (stateObj?.lastName.length < 2) {
      newErr.push('Achternaam')
    } else {
      newErr.filter((it) => it !== 'Achternaam')
    }

    if (stateObj?.company.length < 2) {
      newErr.push('Bedrijf')
    } else {
      newErr.filter((it) => it !== 'Bedrijf')
    }

    const emailRegex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

    if (!emailRegex.test(stateObj?.email)) {
      newErr.push('E-mail')
    } else {
      newErr.filter((it) => it !== 'E-mail')
    }

    const phoneRegex =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

    if (!phoneRegex.test(stateObj?.phone)) {
      newErr.push('Bedrijfstelefoonnummer')
    } else {
      newErr.filter((it) => it !== 'Bedrijfstelefoonnummer')
    }

    setError(newErr)
    setHasChanges(hasChanges + 1)

    if (newErr.length > 0) {
      return
    } else {
      dispatch(create(stateObj))
    }
  }, [dispatch, stateObj, error, hasChanges])

  // const theme = useTheme()
  const change = useCallback(
    (key, value) => {
      console.log({ key, value })
      setStateObj({ ...stateObj, [key]: value })
      switch (key) {
        case 'firstName':
          return setError(error.filter((it) => it !== 'Voornaam'))
        case 'lastName':
          return setError(error.filter((it) => it !== 'Achternaam'))
        case 'company':
          return setError(error.filter((it) => it !== 'Bedrijf'))
        case 'email':
          return setError(error.filter((it) => it !== 'E-mail'))
        case 'phone':
          return setError(error.filter((it) => it !== 'Bedrijfstelefoonnummer'))
        default:
          return null
      }

      // dispatch(create())
    },
    [stateObj, error]
  )
  useEffect(() => {
    if (informationData.created) {
      history.push('/registrationSuccess')
    } else if (informationData.creatingError) {
      // alert(
      //   'Er ging iets mis. Controleer of u alle velden ingevoerd heeft en probeer het opnieuw.'
      // )
    }
  }, [informationData, history])

  return (
    <ThemeProvider theme={theme}>
      <div className="background" key={hasChanges}>
        <div className="white-box">
          <Button
            sx={{ borderColor: '#64748B' }}
            onClick={() => history.push('/')}
            style={{
              marginTop: 10,
              // paddingBottom: 14,
              width: '100%',
            }}
            color="bright"
            variant={'outlined'}
            uppercase={false}
          >
            <img
              style={{
                marginRight: 10,
                width: 14,
              }}
              src={backArrow}
              alt="backArrow"
            />
            <Typography
              textTransform="none"
              color="white"
              style={{ fontSize: 18 }}
            >
              Terug
            </Typography>
          </Button>
          <br />
          <br />
          <img
            style={{
              paddingBottom: 15,
            }}
            src={Logo}
            className="logo"
            alt="Logo"
          />
          <br />
          <TextField
            InputProps={{ style: { fontSize: 16 } }}
            style={{ paddingBottom: 14, left: 'auto' }}
            className="standard-basic"
            label="Voornaam"
            variant="standard"
            helperText={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Voornaam') &&
              'Vul dit veld in'
            }
            error={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Voornaam')
            }
            required
            value={stateObj.firstName}
            onChange={(event) => {
              const val = event.target.value
              change('firstName', val)
            }}
          />
          <br />
          <TextField
            InputProps={{ style: { fontSize: 16 } }}
            style={{ paddingBottom: 14 }}
            className="standard-basic"
            label="Achternaam"
            helperText={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Achternaam') &&
              'Vul dit veld in'
            }
            error={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Achternaam')
            }
            variant="standard"
            required
            value={stateObj.lastName}
            onChange={(event) => {
              const val = event.target.value
              change('lastName', val)
            }}
          />
          <br />
          <TextField
            InputProps={{ style: { fontSize: 16 } }}
            style={{ paddingBottom: 14 }}
            className="standard-basic"
            label="E-mail"
            variant="standard"
            helperText={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'E-mail') &&
              'Vul dit veld in'
            }
            error={
              error && error.length > 0 && error.some((key) => key === 'E-mail')
            }
            required
            value={stateObj.email}
            onChange={(event) => {
              const val = event.target.value
              change('email', val)
            }}
          />
          <br />
          <TextField
            InputProps={{ style: { fontSize: 16 } }}
            style={{ paddingBottom: 14 }}
            className="standard-basic"
            label="Bedrijfstelefoonnummer"
            helperText={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Bedrijfstelefoonnummer') &&
              'Vul dit veld in'
            }
            error={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Bedrijfstelefoonnummer')
            }
            variant="standard"
            required
            value={stateObj.phone}
            onChange={(event) => {
              const val = event.target.value
              change('phone', val)
            }}
          />
          <br />
          <TextField
            InputProps={{ style: { fontSize: 16 } }}
            required
            style={{ paddingBottom: 14 }}
            className="standard-basic"
            label="Bedrijf"
            helperText={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Bedrijf') &&
              'Vul dit veld in'
            }
            error={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Bedrijf')
            }
            variant="standard"
            value={stateObj.company}
            onChange={(event) => {
              const val = event.target.value
              change('company', val)
            }}
          />
          <br />
          <TextField
            InputProps={{ style: { fontSize: 16 } }}
            // required
            style={{ paddingBottom: 14 }}
            className="standard-basic"
            label="Functietitel"
            helperText={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Functietitel') &&
              'Vul dit veld in'
            }
            error={
              error &&
              error.length > 0 &&
              error.some((key) => key === 'Functietitel')
            }
            variant="standard"
            value={stateObj.func}
            onChange={(event) => {
              const val = event.target.value
              change('func', val)
            }}
          />
          <br />
          <FormControl style={{ color: 'white' }} className="selectbox">
            <InputLabel
              style={{
                color: '#bac2be',
                backgroundColor: '#121e53',
                paddingLeft: 6,
                paddingRight: 6,
              }}
            >
              Contact
            </InputLabel>
            <Select
              input={<BootstrapInput />}
              style={{ fontSize: 16 }}
              label="Contact"
              value={stateObj.contact}
              onChange={(event) => {
                const val = event.target.value
                change('contact', val)
              }}
            >
              <MenuItem
                className="menuItem"
                style={{ fontSize: 16, width: '100%' }}
                value={'Ik wil gebeld worden'}
              >
                <Typography style={{ fontSize: 16 }}>
                  Ik wil gebeld worden
                </Typography>
              </MenuItem>
              <MenuItem
                style={{ fontSize: 16, width: '100%' }}
                value={'Ik wil enkel via mail benaderd worden'}
              >
                <Typography style={{ fontSize: 16 }}>
                  Ik wil enkel via mail benaderd worden
                </Typography>
              </MenuItem>

              <MenuItem
                style={{ fontSize: 16, width: '100%' }}
                value={'Ik wil niet benaderd worden'}
              >
                <Typography style={{ fontSize: 16 }}>
                  Ik wil niet benaderd worden
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <div style={{ width: '100%' }}>
            <Button
              color="bright"
              onClick={information}
              className="send"
              type="submit"
              variant={'outlined'}
              style={{
                marginTop: 24,
                width: '100%',
                flex: 1,
                marginBottom: 48,
              }}
            >
              {informationData.creating ? (
                <CircularProgress size={24} color={'primary'} />
              ) : (
                <Typography
                  textTransform="none"
                  color="white"
                  style={{ fontSize: 18 }}
                >
                  Verzenden
                </Typography>
              )}
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Registration
