import * as a from './action'

const initialState = {
  creating: false,
  creatingError: false,
  created: false,
}

const reducer = (
  state = initialState,
  { action, type, payload, meta, error },
) => {
  switch (type) {
    case a.RESET:
      return initialState
    case a.CREATE:
      return {
        creating: true,
        creatingError: false,
        created: false,
      }
    case a.CREATED:
      console.log({ action, type, payload, meta, error })
      return {
        creating: false,
        creatingError: error,
        created: error ? false : true,
      }
    default:
      return state
  }
}

export default reducer



