import React from 'react'
import { useHistory } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import './navbar.css'
import { Button, Typography } from '@mui/material'

import webinar from './img/webinar.webp'
import consult from './img/consult.png'
import demo from './img/demo.png'

const theme = createTheme({
  palette: {
    bright: {
      main: '#fff',
    },
  },
})

function App() {
  const history = useHistory()
  return (
    <ThemeProvider theme={theme}>
      <div className="white-box">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
            flexGrow: 1,
          }}
          className="homeScreen"
        >
          <Button
            sx={{ borderColor: '#64748B', marginTop: 2 }}
            color="bright"
            style={{
              borderRadius: 10,
              marginBottom: 15,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            startIcon={
              <img
                src={webinar}
                style={{
                  height: 75,
                  marginBottom: 16,
                  filter: 'brightness(0) invert(1)',
                }}
                color="white"
                alt={'demo'}
              />
            }
            variant={'outlined'}
            className="registration"
            onClick={() => history.push('registration/webinar')}
          >
            <Typography
              textTransform="none"
              color="white"
              style={{ fontSize: 25 }}
            >
              Inschrijven voor webinar
            </Typography>
          </Button>
          <Button
            sx={{ borderColor: '#64748B' }}
            color="bright"
            style={{
              borderRadius: 10,
              marginBottom: 15,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            startIcon={
              <img
                src={demo}
                style={{
                  height: 75,
                  marginBottom: 16,
                  filter: 'brightness(0) invert(1)',
                }}
                color="white"
                alt={'demo'}
              />
            }
            variant={'outlined'}
            className="registration"
            onClick={() => history.push('registration/demo')}
          >
            <Typography
              textTransform="none"
              color="white"
              style={{ fontSize: 25 }}
            >
              Demo afspraak
            </Typography>
          </Button>
          <Button
            sx={{ borderColor: '#64748B' }}
            onClick={() => history.push('registration/consult')}
            color="bright"
            style={{
              borderRadius: 10,
              marginBottom: 15,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            startIcon={
              <img
                src={consult}
                style={{
                  height: 75,
                  marginBottom: 16,
                  filter: 'brightness(0) invert(1)',
                }}
                color="white"
                alt={'demo'}
              />
            }
            variant={'outlined'}
            className="registration"
          >
            <Typography
              textTransform="none"
              color="white"
              style={{ fontSize: 25 }}
            >
              Afspraak voor een consult
            </Typography>
          </Button>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
