const actionCreator = (type) => (payload, meta, error) => ({
  type,
  payload,
  meta,
  error: error || (payload && payload instanceof Error),
})

export const RESET = 'RESET'
export const reset = actionCreator(RESET)

export const CREATE = 'CREATE'
export const create = actionCreator(CREATE)

export const CREATED = 'CREATED'
export const created = actionCreator(CREATED)
