import { TextField, withStyles } from '@material-ui/core'

const WhiteTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#bac2be', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#bac2be', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white', // Solid underline on focus
    },
    '& label.Mui-focused': {
      color: 'white', // Solid underline on focus
    },
    '& .MuiInputLabel-root': {
      color: '#bac2be',
      fontSize: 16,
    },
  },
})(TextField)

export default WhiteTextField
