import React, { useEffect, useState } from 'react'
import { Button, Typography } from '@mui/material'
import Logo from '../img/img.png'
import '../navbar.css'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { reset } from './action'
import { createTheme, ThemeProvider } from '@mui/material/styles'

function RegisterSuccess() {
  const history = useHistory()
  const [time, setTime] = useState(10)
  const dispatch = useDispatch()
  useEffect(() => {

    dispatch(reset())
    setTimeout(() => history.push('/'), 10000)
  }, [history, dispatch])

  useEffect(() => {
    setTimeout(() => setTime(time - 1), 1000)
  }, [time])

  const theme = createTheme({
    palette: {
      bright: {
        main: '#fff',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <div className='background'>
        <div className='white-box'>
          <img
            style={{
              paddingBottom: 15,
            }}
            className='logo'
            src={Logo}
            alt='Logo'
          />
          <Typography style={{ fontSize: 25, color: 'white' }}>
            Bedankt voor het invullen!
          </Typography>
          <br />
          <Typography textTransform='none' color='white' style={{ fontSize: 15 }}>
            Het formulier is opgestuurd!
          </Typography>
          <br />
          <div className='loader' style={{ color: 'white' }}>
            <Button
              sx={{ borderColor: '#64748B', color: 'white' }}
              color='bright'
              className='send'
              type='submit'
              variant={'outlined'}
              style={{
                width: '100%',
                height: 50,
                marginTop: 24,
                marginBottom: 48,
              }}
              href='/'
            >
              <Typography
                textTransform='none'
                color='white'
                style={{ fontSize: 17 }}
              >
                Terug
              </Typography>
            </Button>
            <Typography style={{ fontSize: 12, color: 'white' }}>
              Je wordt automagisch doorgestuurd over {time} seconden...
            </Typography>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default RegisterSuccess
