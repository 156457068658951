import { put, call, takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import api from 'react-api'

function* create(payload) {
  try {
    const rssss = yield call(api, {
      basePath: 'https://api.pipedrive.com',
      path: '/v1/persons/1',
      method: 'GET',
      params: {
        api_token: '808ac7c199972acb00c06e94fa27708c2c490057',
      },
    })
    console.log({ rssss })
    const organizationResponse = yield call(api, {
      basePath: 'https://api.pipedrive.com',
      path: '/v1/organizations',
      method: 'POST',
      body: {
        name: payload.payload.company,
      },
      params: {
        api_token: '808ac7c199972acb00c06e94fa27708c2c490057',
      },
    })
    const personResponse = yield call(api, {
      basePath: 'https://api.pipedrive.com',
      path: '/v1/persons',
      method: 'POST',
      body: {
        name: payload.payload.firstName + ' ' + payload.payload.lastName,
        phone: [payload.payload.phone],
        email: [payload.payload.email],
        org_id: organizationResponse.data.id,
        job_title: payload.payload.func,
        cc1377d00bc622def42087899610cb64b3fee27f: payload.payload.contact,
      },
      params: {
        api_token: '808ac7c199972acb00c06e94fa27708c2c490057',
      },
    })
    const leadResponse = yield call(api, {
      basePath: 'https://api.pipedrive.com',
      path: '/v1/leads',
      method: 'POST',
      body: {
        person_id: personResponse.data.id,
        title:
          payload.payload.goal.charAt(0).toUpperCase() +
          payload.payload.goal.slice(1) +
          ' inschrijving',
        label_ids: ['6e0dcf90-5b7a-11ed-bcfb-7582f97ad294'],
      },
      params: {
        api_token: '808ac7c199972acb00c06e94fa27708c2c490057',
      },
    })

    yield put(actions.created(leadResponse))
  } catch (e) {
    console.log({ e })
    yield put(actions.created(e, null, true))
  }
}

export default function* saga() {
  yield takeLatest(actions.CREATE, create)
}
